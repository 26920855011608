<template>
  <div class="flex flex-col">
    <Title :pages="pages">
      
      <Button
        v-if="startable"
        status="primary"
        :loading="starting"
        @click="handleStart"
      >
        {{ isScheduled ? (starting ? "Scheduling..." : "Schedule") : (starting ? "Starting..." : "Start") }}
      </Button>

      <Button
        v-else
        status="primary"
        :loading="stopping"
        :disabled="stopping"
        @click="handleStop"
      >
        {{ isScheduled ? (stopping ? "Unscheduling..." : "Unschedule") : (starting ? "Stoping..." : "Started") }}
      </Button>

      <Button class="ml-3" status="danger" @click="undeploy = true">
        {{ undeploying ? "Undeploying..." : "Undeploy" }}
      </Button>
    </Title>

    <div v-if="loading">loading....</div>
    <div v-else-if="deployment" class="flex-1 flex flex-col">
      <div class="py-3 px-4 sm:px-6 lg:px-8">
        <Card>
          <CardItem
            heading="SEED ID"
            :icon="CalendarIcon"
            :message="deployment.seed_id"
          >
            <template #secondary-heading>
              <p
                :class="{
                  'bg-green-100 text-green-800':
                    deployment.status === 'Deployed',
                  'bg-gray-100 text-gray-800': deployment.status === 'Draft',
                  'bg-blue-100 text-blue-800':
                    deployment.status === 'Deploying',
                  'bg-red-100 text-red-800': deployment.status === 'Failed',
                }"
                class="
                  px-2
                  inline-flex
                  text-xs
                  leading-5
                  font-semibold
                  rounded-full
                "
              >
                {{ deployment.status }}
              </p>
            </template>

            <template #secondary-message>
              <Timestamp :value="deployment.created_at" />
            </template>
          </CardItem>
          <CardItem heading="Owner" :message="deployment.user?.name" />
          <CardItem
            v-if="deployment.template"
            heading="Schedule Template"
            :message="deployment.template"
          />
          <CardItem
            v-if="deployment.cron"
            heading="Cron schedule expressions"
            :message="deployment.cron"
          />
          <CardItem
            v-if="deployment.error"
            heading="Error"
            :message="deployment.error"
          />
        </Card>
      </div>

      <Modal
        :open="undeploy"
        status="danger"
        title="Undeploy SEED"
        action="Undeploy"
        :loading="undeploying"
        @close="undeploy = false"
        @submit="handleUndeploy"
      >
        <p>Do you want to remove {{ deployment.seed_id }}?</p>
      </Modal>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, inject, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { CalendarIcon } from "@heroicons/vue/solid";
import card from "@/components/common/card";
import Modal from "@/components/common/Modal";
import Title from "@/components/common/Title";
import Button from "@/components/common/Button";
import Timestamp from "@/components/common/Timestamp";

export default defineComponent({
  mixins: [card],

  components: {
    Button,
    Modal,
    Title,
    Timestamp,
  },
  setup() {
    const route = useRoute();
    const $axios = inject("axios");

    const loading = ref(true);
    const workflow = ref(null);
    const deployment = ref(null);

    const start = useStart(workflow, deployment);
    const undeploy = useUndeploy(workflow, deployment);

    const pages = computed(() => [
      {
        name: "Workflows",
        href: "workflows.index",
      },
      {
        name: workflow.value ? `${workflow.value.name}` : "View",
        href: "workflows.view",
      },
      {
        name: "Deployments",
        href: "workflows.view",
      },
      {
        name: deployment.value ? `${deployment.value.seed_id}` : "View",
        href: "workflows.deployments.view",
        current: true,
      },
    ]);

    (async function () {
      loading.value = true;

      try {
        const [{ data: item }, { data: deploy }] = await Promise.all([
          $axios.get(`/workflows/${route.params.workflow}`),
          $axios.get(
            `/workflows/${route.params.workflow}/deployments/${route.params.deployment}`
          ),
        ]);

        workflow.value = item.data;
        deployment.value = deploy.data;
      } catch (error) {
        //
      }

      loading.value = false;
    })();

    return {
      ...start,
      ...undeploy,
      pages,
      loading,
      deployment,
      CalendarIcon,
    };
  },
});

function useStart(workflow, deployment) {
  const axios = inject("axios");

  const starting = ref(false);
  const stopping = ref(false);

  const startable = computed(() => {
    return !deployment.value?.started_at;
  });

  const isScheduled = computed(() => {
    return deployment.value?.template?.length;
  });

  async function handleStart() {
    starting.value = true;

    try {
      await axios.post(
        `/workflows/${workflow.value._id}/deployments/${deployment.value._id}/start`
      );
      deployment.value.started_at = new Date().toISOString();
    } catch (error) {
      console.log(error);
    }

    starting.value = false;
  }

  async function handleStop() {
    stopping.value = true;

    try {
      await axios.post(
        `/workflows/${workflow.value._id}/deployments/${deployment.value._id}/stop`
      );
      deployment.value.started_at = null;
    } catch (error) {
      //
    }

    stopping.value = false;
  }

  return {
    starting,
    stopping,
    startable,
    isScheduled,
    handleStop,
    handleStart,
  };
}

function useUndeploy(workflow, deployment) {
  const axios = inject("axios");
  const router = useRouter();

  const undeploy = ref(false);
  const undeploying = ref(false);

  async function handleUndeploy() {
    undeploying.value = true;

    try {
      await axios.delete(
        `/workflows/${workflow.value._id}/deployments/${deployment.value._id}`
      );

      undeploy.value = false;

      router.push({
        name: "workflows.view",
        params: { workflow: workflow.value._id },
      });
    } catch (error) {
      //
    }

    undeploying.value = false;
  }

  return {
    undeploy,
    undeploying,
    handleUndeploy,
  };
}
</script>
